

import { useRoute } from 'vue-router'
import { ref } from 'vue'
import { getAllProductInfo } from '@/api/sales'
import { ProductVo, buildProductVo } from 'momai'
import productForm from '@/views/sales/components/ProductForm.vue'

export default {
  components: { productForm },
  setup: () => {
    const route = useRoute()
    const curProduct = ref<ProductVo>(buildProductVo())
    const { productId } = route.params

    getAllProductInfo(`${productId}`).then(r => {
      curProduct.value = r
    })

    const loading = ref(false)
    return {
      curProduct,
      loading
    }
  }
}
