
import { PropType, defineAsyncComponent } from 'vue'
import { ProductVo } from 'momai'
import { activeTab } from './state'
import ProductMembers from './ProductMembers.vue'

export default {
  components: {
    productCourseInfo: defineAsyncComponent(() => import('../components/ProductCourseInfo.vue')),
    ProductMembers
  },
  props: {
    curProduct: {
      type: Object as PropType<ProductVo>,
      default: null
    }
  },
  setup: () => {
    return {
      activeTab
    }
  }
}
