
import { PropType, ref } from 'vue'
import { ProductVo } from 'momai'
import { api } from '@/api/useAxios'
import { getUserId } from '@/utils/auth'
import { changeShareInfo } from '@/utils/wechat'
import { useRoute } from 'vue-router'

export default {
  props: {
    curProduct: {
      type: Object as PropType<ProductVo>,
      default: null
    }
  },
  setup: () => {
    const active = ref(0)
    const workList = ref([])
    const { productId } = useRoute().params
    const qrywork = () => {
      api.post('/mmpinglun/getPjPageInfoNew', {
        current: 1,
        ordId: productId, // 课程id
        pjType: '3', // 评论类型,0：课程，1：作业，2：评论，3：活动 ,4:打卡
        size: 20
      }).then((r) => {
        workList.value = r.records
      })
    }
    qrywork()
    return {
      active,
      workList
    }
  }
}
